@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap');

* {
  box-sizing: border-box
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  background-color: #fafafa!important;
}

a {
  text-decoration: none
}
.btn-item:hover {
  background-color: #1464DB;
  color: #fff;
}
.btn-item {
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 5px;
  background-color: #eee;
  padding: 7px 13px;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}

button {
  outline: unset;
  border: none;
  background-color: unset;
  cursor: pointer;
}
.mb-3 {
  margin-bottom: 40px!important;
}
.w-100 {
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

main {
  position: relative;
}
.fs-20 {
  font-size: 20px;
}
.MuiPaper-root .MuiTypography-root {
  padding-left: 0!important;
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.MuiPaper-root .MuiDialogContent-root {
  padding-top: 0!important;
  padding-left: 0!important;
  padding-right: 0!important;
}
.burgerMenu {
  font-size: 22px;
  margin-left: 50px;
  margin-top: 8px;
  cursor: pointer;
}
.Menu {
  position: fixed;
  top: 50px;
  width: 15%;
  background-color: #f1f3f8!important;
  height: 100vh;
  min-height: 100vh;
  border-right: 0;
  transition: all .2s;
  -o-transition: all .2s;
  -ms-transition: all .2s;
  -moz-transition: all .2s;
  -webkit-transition: all .2s;
}
.Body {
  transition: all .2s;
  -o-transition: all .2s;
  -ms-transition: all .2s;
  -moz-transition: all .2s;
  -webkit-transition: all .2s;
}
.menuActive .Body {
    width: calc(100% - 60px);
    margin-left: 60px;
}
.menuActive .Menu li span {
  display: none;
}
.menuActive .Menu {
  position: fixed;
  top: 50px;
  width: 60px;
  height: 100vh;
  min-height: 100vh;
}


.bodyContent {
  height: 100%;
  bottom: 0;
  position: absolute;
  width: calc(100% - 380px);
  overflow-y: scroll;
  padding-top: 20px;
}
.bodyContent100 {
    height: 100%;
    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
    overflow-y: scroll;
    padding: 20px 40px;
}
.form-select {
  border: 1px solid #ccc!important;
}
.form-select fieldset {
  border: 0!important;
}
.rightPreview-select .MuiFormControl-root * {
  border: 0;
}
.rightPreview-select .MuiFormControl-root {
  height: 100%!important;
  width: 100%;
  position: absolute;
  top: -3px;
  bottom: 0;
  border: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
.rightPreview-select .MuiSelect-select {
  padding: 30px 12px 15.5px 12px;
}
.rightPreview-select-num {
  font-size: 12px!important;
  color: #999;
}
.rightPreview-select {
  position: relative;
  padding: 8px 12px 3px 12px;
  background-color: rgb(243, 246, 251);
  border-bottom: 1px solid rgb(208, 215, 225);
  height: 60px;
}
.rightPreview-title div {
  font-size: 18px!important;
  font-weight: bold;
}
.rightPreview-title {
  position: relative;
  padding: 8px 12px 3px 12px;
  background-color: #fff;
  border-bottom: 1px solid rgb(208, 215, 225);
  height: 40px;
}
.PreviewContent {
  padding: 10px 12px;
}
.rightPreview {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 340px;
  background-color: rgb(243, 246, 251);
  border-left: 1px solid rgb(208, 215, 225);
}
.liveContent {
  height: 100%;
  width: 90%;
}

.check-text-small .MuiFormControlLabel-label {
  font-size: 13px!important;
  color: #555!important;
}
input, textarea {
  outline: unset;
  border: 0;
}

input, button, textarea {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}
label {
  color: rgba(0, 0, 0, 1)!important;
  font-size: 1.2rem!important;
  line-height: 1!important;
}
.p-relative {
  position:relative;
}
.pt-1 {
  padding-top: 10px;
}
.link:hover {
  text-decoration: underline!important;
}
.link {
  color: rgb(0, 110, 213)!important;
  font-weight: 600!important;
  text-decoration: none!important;
  line-height: 1!important;
    font-size: 14px!important;
}
.btn-close-absolute {
  position: absolute!important;
  top: 15px!important;
  right: 15px!important;
  display: block!important;

}
.label-small {
  font-size: 14px!important;
}
.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.pt-1 {
  padding-top: 10px!important;
}
.pb-1 {
  padding-bottom: 10px!important;
}
.pt-2 {
  padding-top: 20px!important;
}
.pb-2 {
  padding-bottom: 20px!important;
}
.pt-3 {
  padding-top: 30px!important;
}
.pb-3 {
  padding-bottom: 30px!important;
}
.pt-4 {
  padding-top: 40px!important;
}
.pb-4 {
  padding-bottom: 40px!important;
}
.pt-5 {
  padding-top: 50px!important;
}
.pb-5 {
  padding-bottom: 50px!important;
}
.mb--2 {
  margin-bottom: -20px;
}
.mr-05 {
  margin-right: 5px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px!important;
}
.mr-3 {
  margin-right: 30px;
}
.mt-4 {
  margin-top: 40px!important;
}
.mr-4 {
  margin-right: 40px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.btn {
  text-transform: none!important;
  border-radius: 8px!important;
  height: 37px;
  box-shadow: none!important;
}
.btn-w-50 {
  width: 50%;
}
.block-border {
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 20px 15px;
}
.btn-absolute-left:before,
.btn-absolute-left:after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #555;
  width: 100%;
}
.btn-absolute-left:before {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.btn-absolute-left:after {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.quizBlock {
  border: 1px solid rgb(208, 215, 225);
  padding: 30px;
  border-top: 0;
}
.InspectionSaveResult {
  margin-top: -1px;
  font-size: 12px;
}
.topBack {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 50px;
  border-bottom: 1px solid rgb(208, 215, 225);
  background-color: #fff;
  z-index: 998;
}
.BodyFull {
    width: 90%!important;
    margin-left: auto!important;
    margin-right: auto!important;
    max-width: 800px!important;
}
.link-btn-small {
  text-decoration: none!important;
  line-height: 1.1;
}
.link-btn-small:hover {
  background-color: #fafafa;
}
.link-btn-small {
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #ccc;
  color: #1565c0!important;
  display: block!important;
}
.btn-absolute-left {
  position: absolute;
  top: 25px;
  left: 20px;
  width: 25px;
  height: 25px;
  border: 0!important;
  background-color: transparent;
}
.form-control:hover:not(.Mui-disabled):before {
  -webkit-transition: unset;
    transition: unset;
    pointer-events: none;
    border: 0!important;
}
.form-control {
  padding: 0;
}
.MuiBox-root .MuiPaper-root.MuiPaper-elevation1.MuiPaper-elevation {
    box-shadow: unset!important;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}
.form-control label + & {
    margin-top: 0;
}
.hr {
  border-bottom: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
}
.mx--3 {
  margin-left: -20px;
  margin-right: -20px;
}
.form-textarea:focus:hover {
  color: rgb(33, 33, 33);
}
.form-textarea:focus {
  border: 2px solid #1464DB;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
}
.form-textarea:hover {
  color: #555;
}
.form-textarea {
  background-color: transparent;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  border: 2px solid transparent;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;
  line-height: 1.3;
}

textarea.form-control {
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.form-control input {
  padding: 10px 20px;
  border: 0;
}
.form-control:before {
  border: 0!important;
}
.form-control:focus {
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
}
.form-control {
  border: 1px solid #ccc;
  border-radius: 6px;
}
main {
  padding: 0;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  padding: 10px 40px;
  overflow: hidden;
}
.Body.w-100 main {
  padding: 0;
}
h1 {
  text-align: left;
}

.blackBtn {
  padding: 5px 15px;
  border-radius: 16px;
  background-color: black;
  color: white;
  transition: .2s;
}

.blackBtn:hover {
  background-color: rgba(1, 1, 1, 0.8)
}

.preloader {
  position: absolute;
  left: 50%;
  top: 50%;
}
.flex {
  --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex!important;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.jstart {
  justify-content: flex-start!important;
  -webkit-box-pack: flex-start!important;
  -webkit-justify-content: flex-start!important;
  -ms-flex-pack: flex-start!important;
}
.jbetween {
  justify-content: space-between!important;
  -webkit-box-pack: justify!important;
  -webkit-justify-content: space-between!important;
  -ms-flex-pack: justify!important;
}
.jend {
  justify-content: flex-end!important;
  -webkit-box-pack: flex-end!important;
  -webkit-justify-content: flex-end!important;
  -ms-flex-pack: flex-end!important;
}
.jcenter {
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.acenter {
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
}
.App {
  width: 100%;
}
.Body {
  width: 85%;
  margin-left: 15%;
  margin-top: 50px;
}
.p-2 {
  padding: 15px 20px;
}
.card {
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0 0 0 / 20%);
  border-radius: 10px;
}
.item a {
  color: #000;
  font-size: 16px;
}
button {
  z-index: 9!important;
}
.item {
  color: #000;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    box-shadow: unset;
    margin-top: 0;
    margin-bottom: -1px;
    display: block;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgb(224, 224, 224);
}
.btn-delete {
  cursor: pointer;
  color: #999;
  font-size: 20px;
  text-align: center;
}
.table-default.table-tr-pointer .table-default-tr:hover > .table-default-td {
  border-top: 0!important;
  border-bottom: 1px solid transparent!important;
}
.table-default.table-tr-pointer .table-default-tr {
  cursor: pointer;
  transition: all .1s;
  -o-transition: all .1s;
  -ms-transition: all .1;
  -moz-transition: all .1s;
  -webkit-transition: all .1s;
  align-items: stretch!important;
}
.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--bs-gutter-y);
}
textarea {
  resize: none;
}
.fw-10 {
  width: 10%;
  flex: 0 0 10%;
}
.fw-5 {
  width: 5%!important;
  flex: 0 0 5%!important;
}
.fw-90 {
  width: 90%!important;
  flex: 0 0 90%!important;
}
.fw-15 {
  width: 15%!important;
  flex: 0 0 15%!important;
}
.fw-60 {
  width: 60%!important;
  flex: 0 0 60%!important;
}
.fw-35 {
  width: 35%!important;
  flex: 0 0 35%!important;
}
.fw-70 {
  width: 70%;
  flex: 0 0 70%;
}
.fw-20 {
  width: 20%;
  flex: 0 0 20%;
}
.fw-25 {
  width: 25%;
  flex: 0 0 25%;
}
.fw-80 {
  width: 80%;
  flex: 0 0 80%;
}

.w-15 {
  width: 15%;
}
.w-70 {
  width: 70%;
}
.w-20 {
  width: 20%;
}
.w-80 {
  width: 80%;
}
.col {
    flex: 1 0 0%;
}

@media(max-width: 1200px) {
  .fw-10 {
    width: 15%!important;
    flex: 0 0 15%!important;
  }
  .fw-80 {
    width: 70%!important;
    flex: 0 0 70%!important;
  }
  .Menu {
    width: 65px!important;
  }

}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.table-default.table-tr-pointer .table-default-tr:hover {
  box-shadow: 0 0 6px 0 rgba(0 0 0 / 20%);
  z-index: 99;
  position: relative;
}
.table-default .table-default-th {
  border-bottom: 1px solid rgb(208 215 225);
  padding: 13px 15px;
  background-color: rgb(233 238 246);
  text-align: left;
  font-weight: 400;
  flex: 1 0 0%;
}
.table-default .table-default-th a,
.table-default .table-default-td a {
  color: #000;
  text-decoration: underline;
}
.table-default .table-default-td {
  border-bottom: 1px solid rgb(208 215 225);
  padding: 13px 15px;
  background-color: #fff;
  text-align: left;
  flex: 1 0 0%;
}
.table-default-wrap {
  border: 1px solid rgb(208 215 225);
  border-radius: 6px;
  overflow: hidden;
}
.table-default {
  width: 100%;
  
}
@media(max-width: 1000px) {
  .Body {
    width: calc(100% - 65px)!important;
    margin-left: 65px!important;
  }
}
.MuiFormLabel-root {
  overflow: unset!important;
}
.table-folders-handler {
  width: 30px;
}
.lw-100 label {
  width: 100%;
  display: block;
}
.select-group {
  font-weight: bold!important;
}
.dropdown-w .MuiList-root {
  width: 300px;
}
.lineItemDel {
  font-size: 15px;
  padding-bottom: 5px;
}

.AlertInfo {
  padding: 40px 0;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  color: #999;
}
.table-default-btns-object {
  flex: 0 0 100px!important;
}

