.BrandName {
  padding: 10px 30px;
  font-size: 20px;
}
.ul li a {
  color: #727c9f;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  margin-right: 10px;
}
.NavLinkActive {
  color: #1464DB;
  border-radius: 0 50px 50px 0;
}
.icon {
  font-size: 25px;
  display: block;
  width: 35px;
  margin-top: 2px;
  margin-left: -2px;
  line-height: 1;
}
.ul li {
  margin-bottom: 10px;
  line-height: 1;
}
.ul {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  list-style: none;
}
.MenuChild {
  margin: 0!important;
  padding: 0!important;
  list-style: none!important;
}
.MenuChild li {
  margin-bottom: 1px!important;
}
.Menu li {
  border-bottom: 1px solid rgb(224, 224, 224);
  padding-bottom: 10px;
}

.NavLink img {
  opacity: .4;
  margin-right: 12px;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.NavLinkActive img {
   opacity: 1;
}
.NavLink {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
@media(max-width: 1200px) {
  .NavLink span {
    display: none;
  }

}