.AreaBg.active {
  display: block;
}
.AreaBg {
  display: none;
  background-color: rgba(0 0 0 / 50%);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.AreaRight.active {
  right: 0;
}
.AreaRight {
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0 0 0 / 20%);
  position: fixed;
  top: 0;
  right: -110%;
  bottom: 0;
  width: 35%;
  min-width: 350px;
  z-index: 999;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.MuiGrid-item {
    margin-top: 48px!important;
    padding-top: 0!important;
}