.ObjectsBlock .table-default-wrap {
	border-radius: 0;
}
.ObjectsBlock {
	padding-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: 1px solid #ccc;
	padding-right: 0;
}
