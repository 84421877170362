.QuizContent {
  background-color: #fff;
  border: 1px solid rgb(208, 215, 225);
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 15px;
}
.QuizContent fieldset {
  display: none;
}
.QuizNote {
  padding-top: 20px;
}
.QuizContent__title {
  overflow: hidden;
  word-wrap: break-word;
}
.QuizFileItems {
  margin: 0 -4px;
  margin-top: 20px;
}
.QuizFileItem img {
  height: 100%;
  width: auto;
  max-width: 150%;
  min-width: 100%;
  object-fit: cover;
}
.QuizFileItem {
  display: inline-block;
  margin: 0 4px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 0px;
  background-color: #ddd;
}
.QuizContent .MuiOutlinedInput-input {
  border: 1px solid rgb(208, 215, 225)!important;
  border-radius: 6px;
  width: 100%;
  padding: 5px 10px;
  height: 30px;
}
.QuizContentSection-btn,
.QuizContentSection {
  margin-bottom: 8px;
}
.quizBlock .PreviewContent {
    position: relative;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: unset;
    padding: 10px 0;
}
.quizBlock .rightPreview-select {
    border-bottom: 1px solid rgb(208, 215, 225);
    border-left: 1px solid rgb(208, 215, 225);
    border-right: 1px solid rgb(208, 215, 225);
    border-radius: 0 0 10px 10px;
}
.quizBlock {
    border: 0;
    padding: 0;
}

.QuizContentSection.active {
  border-bottom: 3px solid rgb(71, 64, 212);
  border-radius: 0 0 7px 7px;
}
.QuizContentSection-btn {
  position: relative;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  background-color: rgb(71, 64, 212);
  border-radius: 0.75rem;
  border: 0;
  font-size: 15px;
  height: 50px;
}
.QuizContent .form-control {
  min-height: 30px;
  width: 100%;
  padding: 5px 10px;
}
.PreviewContent-page {
  display: none;
}
.PreviewContent-page.active {
  display: block;
}
.QuizContent__title {
  font-size: 15px;
}
.QuizContent-Required {
  border-left: 3px solid red;
}
.QuizContent__body {
  padding-top: 13px;
}
.QuizNote-text:hover {
  background-color: #eee;
}
.QuizNote-text {
  background-color: rgb(243, 246, 251);
  cursor: pointer;
  padding: 3px 5px;
  border-radius: 5px;
}
.QuizContent__btnSmall:hover {
  background-color: rgb(243, 246, 251);
}
.QuizContent__btnSmall span {
  font-size: 16px;
  margin-right: 5px;
}
.PreviewContent {
  position: absolute;
  bottom: 0;
  top: 100px;
  left: 0;
  right: 0;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.QuizContent__btnSmall {
  position: relative;
  border: 0;
  background-color: transparent;
  border-radius: 5px;
  padding: 3px;
  font-size: 12px;
  color: #121212;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.QuizContent__footer {
  margin-top: 18px;
}
.QuizContent__btn {
  border: 1px solid rgb(208, 215, 225);
  border-radius: 7px;
  padding-left: 20px;
  padding-right: 20px;
  height: 38px;
  font-size: 14px;
  color: rgb(71, 64, 212);
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.QuizContent__btns .QuizContent__btn {
  margin-right: 10px;
}
.QuizContent__btns {
  display: flex;
  align-items: flex-start;
}
.QuizContent__btn:hover, .QuizContent__btn:focus {
    background-color: rgb(243, 246, 251);
}

.QuizContentPreview .QuizContent__btns .QuizContent__btn {
  margin-right: 0;
}
.QuizContentPreview .QuizContent__btns {
  display: block;
}
.QuizContentPreview .QuizContent__btn {
  width: 100%;
  display: block;
  margin-bottom: 5px;
}
.PreviewContentConducted .QuizContent {
  border: 0;
  border-radius: 15px;
  margin-bottom: 13px;
}
body .PreviewContentConducted .QuizContent__btns {
  display: block!important;
}

.QuizContentItems>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.QuizContentItems {
  --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}
.QuizContentItem {
  padding: 20px 15px;
  margin-top: -10px;
  margin-bottom: -15px;
  flex: 0 0 auto;
    width: 50%;
}
.QuizContentItemBig {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}
.QuizContentTitle {
  font-size: 25px;
  font-weight: 600;
}
.QuizContentBl {
  border-left: 1px solid #ccc;
}
.QuizContentBr {
  border-right: 1px solid #ccc;
}
.QuizContentBb {
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: -15px;
}
.PreviewContentConducted .QuizContent__btn {
  width: auto!important;
  display: inline-block!important;
  border-radius: 100px;
}
.PreviewContentConductedTitle {
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.PreviewContentConducted {
  background-color: #E9EEF6;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 13px;
}
@media(min-width: 700px) {
  .quizBlockWork .QuizContent__btns {
    display: -ms-flexbox;
      display: flex!important;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-left: -7px;
      margin-right: -7px;
  }
  .quizBlockWork .QuizContent__body .MuiButton-sizeMedium {
    max-width: 230px;
  }
  .quizBlockWork .QuizContent__btns button {
    -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      margin-left: 7px;
      margin-right: 7px;
  }
}