.ChecklistItemType {
	width: 300px;
    background-color: #fff;
    padding: 10px 0 10px 20px;
}
.ChecklistQuestionBtn {
	padding: 5px 10px;
	margin: 0 2px;
	line-height: 1;
	border-radius: 3px;
	background-color: #555;
	color: #fff;
	cursor: pointer;
}
.ChecklistQuestionTitle {
	cursor: pointer;
	color: #999;
}
.ChecklistItem .accordion-title {
	padding-left: 5px;
	padding-right: 5px;
}
.ChecklistItemTitleI .inputQuestion:focus {
	outline: .5px solid #1464DB;
}
.ChecklistItemTitleI .inputQuestion {
	word-wrap: break-word!important;
	padding-left: 10px!important;
	padding-right: 10px!important;
	display: block;
	position: relative;
}
.ChecklistItemTitleI {
	margin-left: 0px!important;
	padding-right: 10px!important;
}
.type-dropdown {
  display: none;
}
.multiple-variant-items {
	margin-top: 5px;
}
.mvi-color {
	width: 30px;
	padding: 5px 0 0 0!important;
	text-align: right;
}
.inputChecklist:focus:hover {
  color: rgb(33, 33, 33);
}
.inputChecklist:focus {
  border: 2px solid #1464DB;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
}
.inputChecklist:hover {
  color: #555;
}
.inputChecklist {
  background-color: transparent;
  width: 100%;
  font-size: 27px;
  font-weight: 500;
  border: 2px solid transparent;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.2;
}
.form-input-small {
	width: 50px;
	padding: 1px 6px;
	border-radius: 5px;
	border: 1px solid #ddd;
}
.btn-more:hover {
	background-color: #f4f4f4!important;
}
.btn-more {
	border: 0!important;
	box-shadow: unset!important;
	background-color: transparent!important;
	font-size: 30px!important;
	color: #666!important;
}
.accordion-content.accordion-content-body {
	padding-top: 5px!important;
	padding-bottom: 5px!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	background-color: #FAFAFA;
}
.ChecklistPageItem .accordion-content.accordion-content-body {
	border-top: 1px solid rgb(224, 224, 224);
}
.ChecklistPageItem .accordion-content {
    padding: 0 0px;
    
}
.ChecklistPageItem {
	margin-bottom: 5px;
}
.ChecklistPageItem .ChecklistItemTitle {
    margin-left: 10px!important;
}
.ChecklistPageItem .accordion-arrow {
	cursor: pointer;
}
.ChecklistPageItem .accordion-title {
	font-family: Roboto, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    color: rgb(33, 33, 33);
}
.ChecklistPageItem .ChecklistItemType {
	font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: rgb(33, 33, 33);
    border-left: 1px solid rgb(224, 224, 224);
}
.mvi-title textarea::-webkit-resizer {
  display: none;
}
.mvi-title textarea {
	width: 100%;
	height: 25px;
	min-height: 25px;
	border: 1px solid transparent;
	padding: 3px 0;
	border-radius: 5px;
	line-height: 1.4;
}
.mvi-title textarea:focus {
	border: 1px solid rgb(0, 110, 213);
	padding: 3px 10px;
	line-height: 1.4;
}
.mvi-title {
	font-size: 16px;
	font-weight: 500;
	width: calc(100% - 30px)!important;
	padding: 3px 0!important;
}
.multiple-variant-item {
	background-color: #fff;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	margin-bottom: -1px;
	padding: 15px 20px;
}
.type-dropdown.active,
.TransparentBg.active {
  display: block;
}
.TransparentBg {
  display: none;
  background-color: rgba(0 0 0 / 10%);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.type-dropdown .td-content {
	padding: 15px 20px 10px 20px;
}
.type-dropdown {
	z-index: 9999;
	top: 70px;
	right: 40px;
	border-radius: 6px;
	position: fixed;
	width: 600px;
	max-width: 100%;
	background-color: #fff;
	box-shadow: rgb(66 66 66 / 8%) 0px 0px 7px, rgb(66 66 66 / 10%) 0px 8px 10px;
}
.border-right-1 {
	border-right: 1px solid rgb(224, 224, 224);
}
.multiple-item:hover {
	background-color: #f1f1f1;
}
.multiple-item span,
.multiple-item div {
	cursor: pointer;
}
.multiple-item {
	padding: 7px 20px;
	cursor: pointer;
	transition: all .4s;
	-o-transition: all .4s;
	-ms-transition: all .4s;
	-moz-transition: all .4s;
	-webkit-transition: all .4s;
}