@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&display=swap);
* {
  box-sizing: border-box
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  background-color: #fafafa!important;
}

a {
  text-decoration: none
}
.btn-item:hover {
  background-color: #1464DB;
  color: #fff;
}
.btn-item {
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 5px;
  background-color: #eee;
  padding: 7px 13px;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}

button {
  outline: unset;
  border: none;
  background-color: unset;
  cursor: pointer;
}
.mb-3 {
  margin-bottom: 40px!important;
}
.w-100 {
  width: 100%!important;
  margin-left: 0!important;
  margin-right: 0!important;
}

main {
  position: relative;
}
.fs-20 {
  font-size: 20px;
}
.MuiPaper-root .MuiTypography-root {
  padding-left: 0!important;
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.MuiPaper-root .MuiDialogContent-root {
  padding-top: 0!important;
  padding-left: 0!important;
  padding-right: 0!important;
}
.burgerMenu {
  font-size: 22px;
  margin-left: 50px;
  margin-top: 8px;
  cursor: pointer;
}
.Menu {
  position: fixed;
  top: 50px;
  width: 15%;
  background-color: #f1f3f8!important;
  height: 100vh;
  min-height: 100vh;
  border-right: 0;
  transition: all .2s;
  -o-transition: all .2s;
  -ms-transition: all .2s;
  -moz-transition: all .2s;
  -webkit-transition: all .2s;
}
.Body {
  transition: all .2s;
  -o-transition: all .2s;
  -ms-transition: all .2s;
  -moz-transition: all .2s;
  -webkit-transition: all .2s;
}
.menuActive .Body {
    width: calc(100% - 60px);
    margin-left: 60px;
}
.menuActive .Menu li span {
  display: none;
}
.menuActive .Menu {
  position: fixed;
  top: 50px;
  width: 60px;
  height: 100vh;
  min-height: 100vh;
}


.bodyContent {
  height: 100%;
  bottom: 0;
  position: absolute;
  width: calc(100% - 380px);
  overflow-y: scroll;
  padding-top: 20px;
}
.bodyContent100 {
    height: 100%;
    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
    overflow-y: scroll;
    padding: 20px 40px;
}
.form-select {
  border: 1px solid #ccc!important;
}
.form-select fieldset {
  border: 0!important;
}
.rightPreview-select .MuiFormControl-root * {
  border: 0;
}
.rightPreview-select .MuiFormControl-root {
  height: 100%!important;
  width: 100%;
  position: absolute;
  top: -3px;
  bottom: 0;
  border: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
.rightPreview-select .MuiSelect-select {
  padding: 30px 12px 15.5px 12px;
}
.rightPreview-select-num {
  font-size: 12px!important;
  color: #999;
}
.rightPreview-select {
  position: relative;
  padding: 8px 12px 3px 12px;
  background-color: rgb(243, 246, 251);
  border-bottom: 1px solid rgb(208, 215, 225);
  height: 60px;
}
.rightPreview-title div {
  font-size: 18px!important;
  font-weight: bold;
}
.rightPreview-title {
  position: relative;
  padding: 8px 12px 3px 12px;
  background-color: #fff;
  border-bottom: 1px solid rgb(208, 215, 225);
  height: 40px;
}
.PreviewContent {
  padding: 10px 12px;
}
.rightPreview {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 340px;
  background-color: rgb(243, 246, 251);
  border-left: 1px solid rgb(208, 215, 225);
}
.liveContent {
  height: 100%;
  width: 90%;
}

.check-text-small .MuiFormControlLabel-label {
  font-size: 13px!important;
  color: #555!important;
}
input, textarea {
  outline: unset;
  border: 0;
}

input, button, textarea {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}
label {
  color: rgba(0, 0, 0, 1)!important;
  font-size: 1.2rem!important;
  line-height: 1!important;
}
.p-relative {
  position:relative;
}
.pt-1 {
  padding-top: 10px;
}
.link:hover {
  text-decoration: underline!important;
}
.link {
  color: rgb(0, 110, 213)!important;
  font-weight: 600!important;
  text-decoration: none!important;
  line-height: 1!important;
    font-size: 14px!important;
}
.btn-close-absolute {
  position: absolute!important;
  top: 15px!important;
  right: 15px!important;
  display: block!important;

}
.label-small {
  font-size: 14px!important;
}
.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.ml-1 {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.pt-1 {
  padding-top: 10px!important;
}
.pb-1 {
  padding-bottom: 10px!important;
}
.pt-2 {
  padding-top: 20px!important;
}
.pb-2 {
  padding-bottom: 20px!important;
}
.pt-3 {
  padding-top: 30px!important;
}
.pb-3 {
  padding-bottom: 30px!important;
}
.pt-4 {
  padding-top: 40px!important;
}
.pb-4 {
  padding-bottom: 40px!important;
}
.pt-5 {
  padding-top: 50px!important;
}
.pb-5 {
  padding-bottom: 50px!important;
}
.mb--2 {
  margin-bottom: -20px;
}
.mr-05 {
  margin-right: 5px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px!important;
}
.mr-3 {
  margin-right: 30px;
}
.mt-4 {
  margin-top: 40px!important;
}
.mr-4 {
  margin-right: 40px;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.btn {
  text-transform: none!important;
  border-radius: 8px!important;
  height: 37px;
  box-shadow: none!important;
}
.btn-w-50 {
  width: 50%;
}
.block-border {
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 20px 15px;
}
.btn-absolute-left:before,
.btn-absolute-left:after {
  position: absolute;
  content: "";
  height: 1px;
  background-color: #555;
  width: 100%;
}
.btn-absolute-left:before {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.btn-absolute-left:after {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.quizBlock {
  border: 1px solid rgb(208, 215, 225);
  padding: 30px;
  border-top: 0;
}
.InspectionSaveResult {
  margin-top: -1px;
  font-size: 12px;
}
.topBack {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 50px;
  border-bottom: 1px solid rgb(208, 215, 225);
  background-color: #fff;
  z-index: 998;
}
.BodyFull {
    width: 90%!important;
    margin-left: auto!important;
    margin-right: auto!important;
    max-width: 800px!important;
}
.link-btn-small {
  text-decoration: none!important;
  line-height: 1.1;
}
.link-btn-small:hover {
  background-color: #fafafa;
}
.link-btn-small {
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  border-radius: 7px;
  border: 1px solid #ccc;
  color: #1565c0!important;
  display: block!important;
}
.btn-absolute-left {
  position: absolute;
  top: 25px;
  left: 20px;
  width: 25px;
  height: 25px;
  border: 0!important;
  background-color: transparent;
}
.form-control:hover:not(.Mui-disabled):before {
  transition: unset;
    pointer-events: none;
    border: 0!important;
}
.form-control {
  padding: 0;
}
.MuiBox-root .MuiPaper-root.MuiPaper-elevation1.MuiPaper-elevation {
    box-shadow: unset!important;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}
.form-control label + & {
    margin-top: 0;
}
.hr {
  border-bottom: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 20px;
}
.mx--3 {
  margin-left: -20px;
  margin-right: -20px;
}
.form-textarea:focus:hover {
  color: rgb(33, 33, 33);
}
.form-textarea:focus {
  border: 2px solid #1464DB;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
}
.form-textarea:hover {
  color: #555;
}
.form-textarea {
  background-color: transparent;
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  border: 2px solid transparent;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;
  line-height: 1.3;
}

textarea.form-control {
  padding: 10px 20px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.form-control input {
  padding: 10px 20px;
  border: 0;
}
.form-control:before {
  border: 0!important;
}
.form-control:focus {
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
}
.form-control {
  border: 1px solid #ccc;
  border-radius: 6px;
}
main {
  padding: 0;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  padding: 10px 40px;
  overflow: hidden;
}
.Body.w-100 main {
  padding: 0;
}
h1 {
  text-align: left;
}

.blackBtn {
  padding: 5px 15px;
  border-radius: 16px;
  background-color: black;
  color: white;
  transition: .2s;
}

.blackBtn:hover {
  background-color: rgba(1, 1, 1, 0.8)
}

.preloader {
  position: absolute;
  left: 50%;
  top: 50%;
}
.flex {
  --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
  flex-wrap: wrap;
  display: flex!important;
  flex-direction: row;
}
.jstart {
  justify-content: flex-start!important;
  -webkit-box-pack: flex-start!important;
  -webkit-justify-content: flex-start!important;
  -ms-flex-pack: flex-start!important;
}
.jbetween {
  justify-content: space-between!important;
  -webkit-box-pack: justify!important;
  -webkit-justify-content: space-between!important;
  -ms-flex-pack: justify!important;
}
.jend {
  justify-content: flex-end!important;
  -webkit-box-pack: flex-end!important;
  -webkit-justify-content: flex-end!important;
  -ms-flex-pack: flex-end!important;
}
.jcenter {
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}
.acenter {
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
}
.App {
  width: 100%;
}
.Body {
  width: 85%;
  margin-left: 15%;
  margin-top: 50px;
}
.p-2 {
  padding: 15px 20px;
}
.card {
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0 0 0 / 20%);
  border-radius: 10px;
}
.item a {
  color: #000;
  font-size: 16px;
}
button {
  z-index: 9!important;
}
.item {
  color: #000;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    box-shadow: unset;
    margin-top: 0;
    margin-bottom: -1px;
    display: block;
    width: 100%;
    font-size: 16px;
    border: 1px solid rgb(224, 224, 224);
}
.btn-delete {
  cursor: pointer;
  color: #999;
  font-size: 20px;
  text-align: center;
}
.table-default.table-tr-pointer .table-default-tr:hover > .table-default-td {
  border-top: 0!important;
  border-bottom: 1px solid transparent!important;
}
.table-default.table-tr-pointer .table-default-tr {
  cursor: pointer;
  transition: all .1s;
  -o-transition: all .1s;
  -ms-transition: all .1;
  -moz-transition: all .1s;
  -webkit-transition: all .1s;
  align-items: stretch!important;
}
.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--bs-gutter-y);
}
textarea {
  resize: none;
}
.fw-10 {
  width: 10%;
  flex: 0 0 10%;
}
.fw-5 {
  width: 5%!important;
  flex: 0 0 5%!important;
}
.fw-90 {
  width: 90%!important;
  flex: 0 0 90%!important;
}
.fw-15 {
  width: 15%!important;
  flex: 0 0 15%!important;
}
.fw-60 {
  width: 60%!important;
  flex: 0 0 60%!important;
}
.fw-35 {
  width: 35%!important;
  flex: 0 0 35%!important;
}
.fw-70 {
  width: 70%;
  flex: 0 0 70%;
}
.fw-20 {
  width: 20%;
  flex: 0 0 20%;
}
.fw-25 {
  width: 25%;
  flex: 0 0 25%;
}
.fw-80 {
  width: 80%;
  flex: 0 0 80%;
}

.w-15 {
  width: 15%;
}
.w-70 {
  width: 70%;
}
.w-20 {
  width: 20%;
}
.w-80 {
  width: 80%;
}
.col {
    flex: 1 0;
}

@media(max-width: 1200px) {
  .fw-10 {
    width: 15%!important;
    flex: 0 0 15%!important;
  }
  .fw-80 {
    width: 70%!important;
    flex: 0 0 70%!important;
  }
  .Menu {
    width: 65px!important;
  }

}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.table-default.table-tr-pointer .table-default-tr:hover {
  box-shadow: 0 0 6px 0 rgba(0 0 0 / 20%);
  z-index: 99;
  position: relative;
}
.table-default .table-default-th {
  border-bottom: 1px solid rgb(208 215 225);
  padding: 13px 15px;
  background-color: rgb(233 238 246);
  text-align: left;
  font-weight: 400;
  flex: 1 0;
}
.table-default .table-default-th a,
.table-default .table-default-td a {
  color: #000;
  text-decoration: underline;
}
.table-default .table-default-td {
  border-bottom: 1px solid rgb(208 215 225);
  padding: 13px 15px;
  background-color: #fff;
  text-align: left;
  flex: 1 0;
}
.table-default-wrap {
  border: 1px solid rgb(208 215 225);
  border-radius: 6px;
  overflow: hidden;
}
.table-default {
  width: 100%;
  
}
@media(max-width: 1000px) {
  .Body {
    width: calc(100% - 65px)!important;
    margin-left: 65px!important;
  }
}
.MuiFormLabel-root {
  overflow: unset!important;
}
.table-folders-handler {
  width: 30px;
}
.lw-100 label {
  width: 100%;
  display: block;
}
.select-group {
  font-weight: bold!important;
}
.dropdown-w .MuiList-root {
  width: 300px;
}
.lineItemDel {
  font-size: 15px;
  padding-bottom: 5px;
}

.AlertInfo {
  padding: 40px 0;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  color: #999;
}
.table-default-btns-object {
  flex: 0 0 100px!important;
}


.AreaBg.active {
  display: block;
}
.AreaBg {
  display: none;
  background-color: rgba(0 0 0 / 50%);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.AreaRight.active {
  right: 0;
}
.AreaRight {
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0 0 0 / 20%);
  position: fixed;
  top: 0;
  right: -110%;
  bottom: 0;
  width: 35%;
  min-width: 350px;
  z-index: 999;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.MuiGrid-item {
    margin-top: 48px!important;
    padding-top: 0!important;
}
.Menu_BrandName__2oY8X {
  padding: 10px 30px;
  font-size: 20px;
}
.Menu_ul__3ca1x li a {
  color: #727c9f;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  margin-right: 10px;
}
.Menu_NavLinkActive__zzGut {
  color: #1464DB;
  border-radius: 0 50px 50px 0;
}
.Menu_icon__3Bynn {
  font-size: 25px;
  display: block;
  width: 35px;
  margin-top: 2px;
  margin-left: -2px;
  line-height: 1;
}
.Menu_ul__3ca1x li {
  margin-bottom: 10px;
  line-height: 1;
}
.Menu_ul__3ca1x {
  margin: 0;
  padding: 0;
  margin-top: 15px;
  list-style: none;
}
.Menu_MenuChild__28kP2 {
  margin: 0!important;
  padding: 0!important;
  list-style: none!important;
}
.Menu_MenuChild__28kP2 li {
  margin-bottom: 1px!important;
}
.Menu_Menu__6Dfmz li {
  border-bottom: 1px solid rgb(224, 224, 224);
  padding-bottom: 10px;
}

.Menu_NavLink__1TMsk img {
  opacity: .4;
  margin-right: 12px;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.Menu_NavLinkActive__zzGut img {
   opacity: 1;
}
.Menu_NavLink__1TMsk {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
@media(max-width: 1200px) {
  .Menu_NavLink__1TMsk span {
    display: none;
  }

}
.Header_mainHeader__3HZ8u {
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    text-align: right;
    padding: 5px 10px;
    border-bottom: 1px solid #eee;
    height: 50px;
    background-color: #1464DB;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.Header_mainHeader__3HZ8u nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Header_mainHeader__3HZ8u > div {
    width: 100%;
}
.Header_logo__3zKiT {
    height: auto!important;
    min-height: auto!important;
    max-height: auto!important;
    min-width: auto!important;
    max-width: auto!important;
}
.Header_mainHeader__3HZ8u a {
    transition: all .2s linear;
    border-bottom: 2px solid transparent;
    font-size: 18px;

    display: inline-block;
    margin: 0 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Header_mainHeader__3HZ8u a:hover {
    color: #d15700
}

.Header_btn__1a-uD {
    background-color: crimson;
}

.Header_active__15day {
    color: crimson;
    font-weight: 600;
}
.blogpage {
    position: relative;
}

.preloader {
    position: absolute;
    left: 50%;
    top: 50%;
}
.PageItemTitle textarea {
   vertical-align:middle!important;
   min-height: 23px;
   max-height: 180px;
   line-height: 1.2;
}
.PageItemTitle {
	flex: 0 0 80%;
}
.table-default-title {
	flex: 0 0 40%!important;
	max-width: 40%!important;
    word-wrap: break-word!important;
}
.table-default-small {
	font-size: 23px;
	color: #ccc;
}
.table-default-small-r {
	flex: 0 0 4%!important;
	padding-right: 15px!important;
	padding-left: 0!important;
	font-size: 23px;
	color: #ccc;
}
.table-default-small2 {
	flex: 0 0 5%!important;
	padding-left: 15px!important;
	padding-right: 0!important;
}
.table-default-btns {
	flex: 0 0 4%!important;
	color: #ccc!important;
}
.table-default-small {
	flex: 0 0 4%!important;
	padding-left: 15px!important;
	padding-right: 0!important;
}
.addNewPost {
    text-align: left;
}
.d-none {
	display: none;
}
.Table tr {
	border-bottom: 1px solid #eee;
}
.Table {
	width: 100%;
}
.checklist-title {
	font-weight: bold;
	text-decoration: none!important;
	color: rgb(16, 14, 32)!important;
}
.checklist-descritpion {
	margin-top: 5px;
	font-size: 13px;
	line-height: 1.2;
	max-width: 300px;
}
.ObjectsBlock .table-default-wrap {
	border-radius: 0;
}
.ObjectsBlock {
	padding-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: 1px solid #ccc;
	padding-right: 0;
}
.ObjectsBlock .table-default-wrap {
	border-radius: 0;
}
.ObjectsBlock {
	padding-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: 1px solid #ccc;
	padding-right: 0;
}
.ObjectsBlock .table-default-wrap {
	border-radius: 0;
}
.ObjectsBlock {
	padding-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: 1px solid #ccc;
	padding-right: 0;
}
.ObjectsBlock .table-default-wrap {
	border-radius: 0;
}
.ObjectsBlock {
	padding-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: 1px solid #ccc;
	padding-right: 0;
}

.QuizContent {
  background-color: #fff;
  border: 1px solid rgb(208, 215, 225);
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 15px;
}
.QuizContent fieldset {
  display: none;
}
.QuizNote {
  padding-top: 20px;
}
.QuizContent__title {
  overflow: hidden;
  word-wrap: break-word;
}
.QuizFileItems {
  margin: 0 -4px;
  margin-top: 20px;
}
.QuizFileItem img {
  height: 100%;
  width: auto;
  max-width: 150%;
  min-width: 100%;
  object-fit: cover;
}
.QuizFileItem {
  display: inline-block;
  margin: 0 4px;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 0px;
  background-color: #ddd;
}
.QuizContent .MuiOutlinedInput-input {
  border: 1px solid rgb(208, 215, 225)!important;
  border-radius: 6px;
  width: 100%;
  padding: 5px 10px;
  height: 30px;
}
.QuizContentSection-btn,
.QuizContentSection {
  margin-bottom: 8px;
}
.quizBlock .PreviewContent {
    position: relative;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: unset;
    padding: 10px 0;
}
.quizBlock .rightPreview-select {
    border-bottom: 1px solid rgb(208, 215, 225);
    border-left: 1px solid rgb(208, 215, 225);
    border-right: 1px solid rgb(208, 215, 225);
    border-radius: 0 0 10px 10px;
}
.quizBlock {
    border: 0;
    padding: 0;
}

.QuizContentSection.active {
  border-bottom: 3px solid rgb(71, 64, 212);
  border-radius: 0 0 7px 7px;
}
.QuizContentSection-btn {
  position: relative;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  background-color: rgb(71, 64, 212);
  border-radius: 0.75rem;
  border: 0;
  font-size: 15px;
  height: 50px;
}
.QuizContent .form-control {
  min-height: 30px;
  width: 100%;
  padding: 5px 10px;
}
.PreviewContent-page {
  display: none;
}
.PreviewContent-page.active {
  display: block;
}
.QuizContent__title {
  font-size: 15px;
}
.QuizContent-Required {
  border-left: 3px solid red;
}
.QuizContent__body {
  padding-top: 13px;
}
.QuizNote-text:hover {
  background-color: #eee;
}
.QuizNote-text {
  background-color: rgb(243, 246, 251);
  cursor: pointer;
  padding: 3px 5px;
  border-radius: 5px;
}
.QuizContent__btnSmall:hover {
  background-color: rgb(243, 246, 251);
}
.QuizContent__btnSmall span {
  font-size: 16px;
  margin-right: 5px;
}
.PreviewContent {
  position: absolute;
  bottom: 0;
  top: 100px;
  left: 0;
  right: 0;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.QuizContent__btnSmall {
  position: relative;
  border: 0;
  background-color: transparent;
  border-radius: 5px;
  padding: 3px;
  font-size: 12px;
  color: #121212;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.QuizContent__footer {
  margin-top: 18px;
}
.QuizContent__btn {
  border: 1px solid rgb(208, 215, 225);
  border-radius: 7px;
  padding-left: 20px;
  padding-right: 20px;
  height: 38px;
  font-size: 14px;
  color: rgb(71, 64, 212);
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.QuizContent__btns .QuizContent__btn {
  margin-right: 10px;
}
.QuizContent__btns {
  display: flex;
  align-items: flex-start;
}
.QuizContent__btn:hover, .QuizContent__btn:focus {
    background-color: rgb(243, 246, 251);
}

.QuizContentPreview .QuizContent__btns .QuizContent__btn {
  margin-right: 0;
}
.QuizContentPreview .QuizContent__btns {
  display: block;
}
.QuizContentPreview .QuizContent__btn {
  width: 100%;
  display: block;
  margin-bottom: 5px;
}
.PreviewContentConducted .QuizContent {
  border: 0;
  border-radius: 15px;
  margin-bottom: 13px;
}
body .PreviewContentConducted .QuizContent__btns {
  display: block!important;
}

.QuizContentItems>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.QuizContentItems {
  --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}
.QuizContentItem {
  padding: 20px 15px;
  margin-top: -10px;
  margin-bottom: -15px;
  flex: 0 0 auto;
    width: 50%;
}
.QuizContentItemBig {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
}
.QuizContentTitle {
  font-size: 25px;
  font-weight: 600;
}
.QuizContentBl {
  border-left: 1px solid #ccc;
}
.QuizContentBr {
  border-right: 1px solid #ccc;
}
.QuizContentBb {
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: -15px;
}
.PreviewContentConducted .QuizContent__btn {
  width: auto!important;
  display: inline-block!important;
  border-radius: 100px;
}
.PreviewContentConductedTitle {
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.PreviewContentConducted {
  background-color: #E9EEF6;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 13px;
}
@media(min-width: 700px) {
  .quizBlockWork .QuizContent__btns {
      display: flex!important;
      flex-wrap: wrap;
      margin-left: -7px;
      margin-right: -7px;
  }
  .quizBlockWork .QuizContent__body .MuiButton-sizeMedium {
    max-width: 230px;
  }
  .quizBlockWork .QuizContent__btns button {
    flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      margin-left: 7px;
      margin-right: 7px;
  }
}
.blogpage {
    position: relative;
}

.preloader {
    position: absolute;
    left: 50%;
    top: 50%;
}

.addNewPost {
    text-align: center;
}
.ChecklistItemType {
	width: 300px;
    background-color: #fff;
    padding: 10px 0 10px 20px;
}
.ChecklistQuestionBtn {
	padding: 5px 10px;
	margin: 0 2px;
	line-height: 1;
	border-radius: 3px;
	background-color: #555;
	color: #fff;
	cursor: pointer;
}
.ChecklistQuestionTitle {
	cursor: pointer;
	color: #999;
}
.ChecklistItem .accordion-title {
	padding-left: 5px;
	padding-right: 5px;
}
.ChecklistItemTitleI .inputQuestion:focus {
	outline: .5px solid #1464DB;
}
.ChecklistItemTitleI .inputQuestion {
	word-wrap: break-word!important;
	padding-left: 10px!important;
	padding-right: 10px!important;
	display: block;
	position: relative;
}
.ChecklistItemTitleI {
	margin-left: 0px!important;
	padding-right: 10px!important;
}
.type-dropdown {
  display: none;
}
.multiple-variant-items {
	margin-top: 5px;
}
.mvi-color {
	width: 30px;
	padding: 5px 0 0 0!important;
	text-align: right;
}
.inputChecklist:focus:hover {
  color: rgb(33, 33, 33);
}
.inputChecklist:focus {
  border: 2px solid #1464DB;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
}
.inputChecklist:hover {
  color: #555;
}
.inputChecklist {
  background-color: transparent;
  width: 100%;
  font-size: 27px;
  font-weight: 500;
  border: 2px solid transparent;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.2;
}
.form-input-small {
	width: 50px;
	padding: 1px 6px;
	border-radius: 5px;
	border: 1px solid #ddd;
}
.btn-more:hover {
	background-color: #f4f4f4!important;
}
.btn-more {
	border: 0!important;
	box-shadow: unset!important;
	background-color: transparent!important;
	font-size: 30px!important;
	color: #666!important;
}
.accordion-content.accordion-content-body {
	padding-top: 5px!important;
	padding-bottom: 5px!important;
	padding-left: 20px!important;
	padding-right: 20px!important;
	background-color: #FAFAFA;
}
.ChecklistPageItem .accordion-content.accordion-content-body {
	border-top: 1px solid rgb(224, 224, 224);
}
.ChecklistPageItem .accordion-content {
    padding: 0 0px;
    
}
.ChecklistPageItem {
	margin-bottom: 5px;
}
.ChecklistPageItem .ChecklistItemTitle {
    margin-left: 10px!important;
}
.ChecklistPageItem .accordion-arrow {
	cursor: pointer;
}
.ChecklistPageItem .accordion-title {
	font-family: Roboto, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
    color: rgb(33, 33, 33);
}
.ChecklistPageItem .ChecklistItemType {
	font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: rgb(33, 33, 33);
    border-left: 1px solid rgb(224, 224, 224);
}
.mvi-title textarea::-webkit-resizer {
  display: none;
}
.mvi-title textarea {
	width: 100%;
	height: 25px;
	min-height: 25px;
	border: 1px solid transparent;
	padding: 3px 0;
	border-radius: 5px;
	line-height: 1.4;
}
.mvi-title textarea:focus {
	border: 1px solid rgb(0, 110, 213);
	padding: 3px 10px;
	line-height: 1.4;
}
.mvi-title {
	font-size: 16px;
	font-weight: 500;
	width: calc(100% - 30px)!important;
	padding: 3px 0!important;
}
.multiple-variant-item {
	background-color: #fff;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	margin-bottom: -1px;
	padding: 15px 20px;
}
.type-dropdown.active,
.TransparentBg.active {
  display: block;
}
.TransparentBg {
  display: none;
  background-color: rgba(0 0 0 / 10%);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  transition: all .4s;
  -o-transition: all .4s;
  -ms-transition: all .4s;
  -moz-transition: all .4s;
  -webkit-transition: all .4s;
}
.type-dropdown .td-content {
	padding: 15px 20px 10px 20px;
}
.type-dropdown {
	z-index: 9999;
	top: 70px;
	right: 40px;
	border-radius: 6px;
	position: fixed;
	width: 600px;
	max-width: 100%;
	background-color: #fff;
	box-shadow: rgb(66 66 66 / 8%) 0px 0px 7px, rgb(66 66 66 / 10%) 0px 8px 10px;
}
.border-right-1 {
	border-right: 1px solid rgb(224, 224, 224);
}
.multiple-item:hover {
	background-color: #f1f1f1;
}
.multiple-item span,
.multiple-item div {
	cursor: pointer;
}
.multiple-item {
	padding: 7px 20px;
	cursor: pointer;
	transition: all .4s;
	-o-transition: all .4s;
	-ms-transition: all .4s;
	-moz-transition: all .4s;
	-webkit-transition: all .4s;
}
.accordion-content label span {
  font-size: 14px!important;
}
.accordion-content {
  padding: 10px 20px;
}

.accordion-Section {
  margin-bottom: -1px!important;
}
.accordion-Section > .accordion-content {
  margin-left: 20px;
  /*margin-top: -1px!important;*/
}
.Question-List > .accordion-content > div > div:first-child > .accordion-item.item {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.mvi-title .txtarea {
  margin-left: 30px;
}
.mvi-title {
  position: relative;
}
.accordion-drag-absolute {
  position: absolute;
  font-size: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.accordion-content > div > div:last-child > .item {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.accordion-toggle:hover {
  background-color: rgb(0, 68, 163)!important;
}
.accordion-Question {
  background-color: rgb(255, 255, 255)!important;
}
.accordion-Section {
  background-color: rgb(245, 245, 245)!important;
}
.accordion-toggle {
  box-shadow: unset!important;
  background-color: rgb(0, 110, 213)!important;
  color: #fff!important;
  width: 30px!important;
  height: 30px!important;
  min-width: 30px!important;
  min-height: 30px!important;
}
.accordion-toggle-section:hover {
  background-color: rgb(224, 224, 224)!important;
}
.accordion-toggle-section {
  box-shadow: unset!important;
  background-color: transparent!important;
  color: rgb(112, 112, 112)!important;
  font-size: 24px!important;
  width: 30px!important;
  height: 30px!important;
  min-width: 30px!important;
  min-height: 30px!important;
}

.inputPage:focus:hover {
  color: rgb(33, 33, 33);
}
.inputPage:focus {
  border: 2px solid #1464DB;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
}
.inputPage:hover {
  color: #555;
}
.inputPage {
  background-color: transparent;
  width: 100%;
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.2;
}

.inputQuestion:focus {
  border: 2px solid #1464DB;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
}
.inputQuestion {
  background-color: transparent;
  width: 250px;
  font-weight: 600;
  border: 2px solid transparent;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgb(112, 112, 112);
  font-size: 14px;
  line-height: 1.2;
}
.accordion-item.accordion-Section {
  background-color: transparent!important;
  border: 0;
}
.accordion-item.accordion-Section > .accordion-title {
  background-color: rgb(245, 245, 245)!important;
  border: 1px solid rgb(224, 224, 224)!important;
  padding-right: 0!important;
}
.accordion-Section > .accordion-content,
.accordion-Question > .accordion-content {
  border-bottom: 1px solid rgb(224 224 224);
  display: none;
}
.accordion-Section.active > .accordion-content,
.accordion-Question.active > .accordion-content {
  display: block;
}
.accordion-Section.active > .accordion-title .accordion-trash,
.accordion-Question.active > .accordion-title .accordion-trash {
  display: block;
}
.accordion-Section .item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion-trash {
  position: absolute;
  top: 10%;
  bottom: 0;
  margin: auto 0;
  right: -35px;
  display: none;
}
.accordion-title,
.accordion-item {
  position: relative;
}
.loginForm {
    width: 350px;
    padding: 40px 40px;
    margin: 0 auto 0 auto;
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14), 0px 5px 14px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}
.loginMain {
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/static/media/bg.b20e8e4b.png);
    background-position: center center;
    background-size: 90%;
}


input.loginFormInput:-webkit-autofill,
input.loginFormInput:-webkit-autofill:hover,
input.loginFormInput:-webkit-autofill:focus,
textarea.loginFormInput:-webkit-autofill,
textarea.loginFormInput:-webkit-autofill:hover,
textarea.loginFormInput:-webkit-autofill:focus,
select.loginFormInput:-webkit-autofill,
select.loginFormInput:-webkit-autofill:hover,
select.loginFormInput:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.loginFormInput {
    width: 100%;
    padding: 9px 15px;
    border: 1px solid #757575;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    margin-bottom: 25px!important;
}


.loginFormInput:focus::-webkit-input-placeholder {
    color: transparent;
}


.loginFormInput:focus:-ms-input-placeholder {
    color: transparent;
}


.loginFormInput:focus::placeholder {
    color: transparent;
}

.loginForm button {
    background-color: #1464DB;
    border: 0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 9px 25px;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
}

h2 {
    font-size: 14px;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 15px!important;
    margin-bottom: 15px!important;
}
