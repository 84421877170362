.blogpage {
    position: relative;
}

.preloader {
    position: absolute;
    left: 50%;
    top: 50%;
}
.PageItemTitle textarea {
   vertical-align:middle!important;
   min-height: 23px;
   max-height: 180px;
   line-height: 1.2;
}
.PageItemTitle {
	flex: 0 0 80%;
}
.table-default-title {
	flex: 0 0 40%!important;
	max-width: 40%!important;
    word-wrap: break-word!important;
}
.table-default-small {
	font-size: 23px;
	color: #ccc;
}
.table-default-small-r {
	flex: 0 0 4%!important;
	padding-right: 15px!important;
	padding-left: 0!important;
	font-size: 23px;
	color: #ccc;
}
.table-default-small2 {
	flex: 0 0 5%!important;
	padding-left: 15px!important;
	padding-right: 0!important;
}
.table-default-btns {
	flex: 0 0 4%!important;
	color: #ccc!important;
}
.table-default-small {
	flex: 0 0 4%!important;
	padding-left: 15px!important;
	padding-right: 0!important;
}
.addNewPost {
    text-align: left;
}
.d-none {
	display: none;
}
.Table tr {
	border-bottom: 1px solid #eee;
}
.Table {
	width: 100%;
}
.checklist-title {
	font-weight: bold;
	text-decoration: none!important;
	color: rgb(16, 14, 32)!important;
}
.checklist-descritpion {
	margin-top: 5px;
	font-size: 13px;
	line-height: 1.2;
	max-width: 300px;
}