.mainHeader {
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    text-align: right;
    padding: 5px 10px;
    border-bottom: 1px solid #eee;
    height: 50px;
    background-color: #1464DB;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

.mainHeader nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mainHeader > div {
    width: 100%;
}
.logo {
    height: auto!important;
    min-height: auto!important;
    max-height: auto!important;
    min-width: auto!important;
    max-width: auto!important;
}
.mainHeader a {
    transition: all .2s linear;
    border-bottom: 2px solid transparent;
    font-size: 18px;

    display: inline-block;
    margin: 0 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.mainHeader a:hover {
    color: #d15700
}

.btn {
    background-color: crimson;
}

.active {
    color: crimson;
    font-weight: 600;
}