.blogpage {
    position: relative;
}

.preloader {
    position: absolute;
    left: 50%;
    top: 50%;
}

.addNewPost {
    text-align: center;
}