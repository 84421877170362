.loginForm {
    width: 350px;
    padding: 40px 40px;
    margin: 0 auto 0 auto;
    text-align: left;
    background: #FFFFFF;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14), 0px 5px 14px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}
.loginMain {
    height: calc(100vh - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/src/assets/images/bg.png');
    background-position: center center;
    background-size: 90%;
}


input.loginFormInput:-webkit-autofill,
input.loginFormInput:-webkit-autofill:hover,
input.loginFormInput:-webkit-autofill:focus,
textarea.loginFormInput:-webkit-autofill,
textarea.loginFormInput:-webkit-autofill:hover,
textarea.loginFormInput:-webkit-autofill:focus,
select.loginFormInput:-webkit-autofill,
select.loginFormInput:-webkit-autofill:hover,
select.loginFormInput:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.loginFormInput {
    width: 100%;
    padding: 9px 15px;
    border: 1px solid #757575;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    margin-bottom: 25px!important;
}


.loginFormInput:focus::placeholder {
    color: transparent;
}

.loginForm button {
    background-color: #1464DB;
    border: 0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 9px 25px;
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
}

h2 {
    font-size: 14px;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 15px!important;
    margin-bottom: 15px!important;
}