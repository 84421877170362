.accordion-content label span {
  font-size: 14px!important;
}
.accordion-content {
  padding: 10px 20px;
}

.accordion-Section {
  margin-bottom: -1px!important;
}
.accordion-Section > .accordion-content {
  margin-left: 20px;
  /*margin-top: -1px!important;*/
}
.Question-List > .accordion-content > div > div:first-child > .accordion-item.item {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.mvi-title .txtarea {
  margin-left: 30px;
}
.mvi-title {
  position: relative;
}
.accordion-drag-absolute {
  position: absolute;
  font-size: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.accordion-content > div > div:last-child > .item {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.accordion-toggle:hover {
  background-color: rgb(0, 68, 163)!important;
}
.accordion-Question {
  background-color: rgb(255, 255, 255)!important;
}
.accordion-Section {
  background-color: rgb(245, 245, 245)!important;
}
.accordion-toggle {
  box-shadow: unset!important;
  background-color: rgb(0, 110, 213)!important;
  color: #fff!important;
  width: 30px!important;
  height: 30px!important;
  min-width: 30px!important;
  min-height: 30px!important;
}
.accordion-toggle-section:hover {
  background-color: rgb(224, 224, 224)!important;
}
.accordion-toggle-section {
  box-shadow: unset!important;
  background-color: transparent!important;
  color: rgb(112, 112, 112)!important;
  font-size: 24px!important;
  width: 30px!important;
  height: 30px!important;
  min-width: 30px!important;
  min-height: 30px!important;
}

.inputPage:focus:hover {
  color: rgb(33, 33, 33);
}
.inputPage:focus {
  border: 2px solid #1464DB;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
}
.inputPage:hover {
  color: #555;
}
.inputPage {
  background-color: transparent;
  width: 100%;
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.2;
}

.inputQuestion:focus {
  border: 2px solid #1464DB;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
}
.inputQuestion {
  background-color: transparent;
  width: 250px;
  font-weight: 600;
  border: 2px solid transparent;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgb(112, 112, 112);
  font-size: 14px;
  line-height: 1.2;
}
.accordion-item.accordion-Section {
  background-color: transparent!important;
  border: 0;
}
.accordion-item.accordion-Section > .accordion-title {
  background-color: rgb(245, 245, 245)!important;
  border: 1px solid rgb(224, 224, 224)!important;
  padding-right: 0!important;
}
.accordion-Section > .accordion-content,
.accordion-Question > .accordion-content {
  border-bottom: 1px solid rgb(224 224 224);
  display: none;
}
.accordion-Section.active > .accordion-content,
.accordion-Question.active > .accordion-content {
  display: block;
}
.accordion-Section.active > .accordion-title .accordion-trash,
.accordion-Question.active > .accordion-title .accordion-trash {
  display: block;
}
.accordion-Section .item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion-trash {
  position: absolute;
  top: 10%;
  bottom: 0;
  margin: auto 0;
  right: -35px;
  display: none;
}
.accordion-title,
.accordion-item {
  position: relative;
}